import { APP_ENV } from './APP_ENV';

const isProd = APP_ENV === 'production';

export const REVALIDATES = {
  index: isProd ? 600 : 240,
  checkout: 86400, // 1 day
  shop: isProd ? 360 : 240,
  category: isProd ? 360 : 240,
  product: isProd ? 360 : 240,
  feedback: isProd ? 7200 : 240
};
