import { FACEBOOK_EVENT_NAMES } from 'domain/constants/FACEBOOK_EVENT_NAMES';
import { GOOGLE_EVENT_NAMES } from 'domain/constants/GOOGLE_EVENT_NAMES';
import { getNameWithColor } from '../products/getNameWithColor';
import { facebookEvent } from './facebook';
import { googleEvent } from './google';
import { googleAdsConversion } from './googleAds';

const CURRENCY = 'UAH';

const addToCart = (
  category: string,
  name: string,
  color: string | undefined,
  quantity: number,
  price: number,
  productItemCode: string
) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.addToCart, {
      category,
      label: getNameWithColor(name, color),
      value: price,
      quantity,
      currency: CURRENCY
    });

    facebookEvent(FACEBOOK_EVENT_NAMES.addToCart, {
      value: price,
      currency: CURRENCY,
      content_name: name,
      content_type: 'product',
      content_ids: [productItemCode],
      content_category: category,
      num_items: quantity
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const removeFromCart = (
  category: string,
  name: string,
  color: string | undefined,
  quantity: number
) => {
  try {
    const nameWithColor = getNameWithColor(name, color);

    googleEvent(GOOGLE_EVENT_NAMES.removeFromCart, {
      category,
      label: nameWithColor,
      value: quantity
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.removeFromCart,
      {
        content_category: category,
        content_name: nameWithColor,
        value: quantity
      },
      true
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const beginCheckOut = (
  totalPrice: number,
  products: {
    category: string;
    name: string;
    quantity: number;
    price: number;
    productItemCode: string;
  }[]
) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.beginCheckOut, {
      value: totalPrice
    });

    products.forEach(({ category, name, quantity, price, productItemCode }) => {
      facebookEvent(FACEBOOK_EVENT_NAMES.beginCheckOut, {
        value: price,
        currency: CURRENCY,
        content_name: name,
        content_type: 'product',
        content_ids: [productItemCode],
        content_category: category,
        num_items: quantity
      });
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const checkOutProgress = (step: number) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.checkOutProgress, {
      value: step
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.checkOutProgress,
      {
        value: step
      },
      false
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const purchase = (
  {
    totalPrice,
    paymentMethod,
    id
  }: {
    totalPrice: number;
    paymentMethod: string;
    id: string;
  },
  products: {
    category: string;
    name: string;
    quantity: number;
    price: number;
    productItemCode: string;
  }[]
) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.purchase, {
      value: totalPrice,
      category: paymentMethod
    });

    products.forEach(({ category, name, quantity, price, productItemCode }) => {
      facebookEvent(FACEBOOK_EVENT_NAMES.purchase, {
        value: price,
        currency: CURRENCY,
        content_name: name,
        content_type: 'product',
        content_ids: [productItemCode],
        content_category: category,
        num_items: quantity
      });
    });

    googleAdsConversion(id);
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const purchaseError = (errorMessage: string) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.purchaseError, {
      category: 'Error',
      label: errorMessage
    });

    facebookEvent(
      FACEBOOK_EVENT_NAMES.purchaseError,
      {
        content_category: 'Error',
        content_name: errorMessage
      },
      true
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const checkoutValidationError = (step: string, errorMessage: string) => {
  try {
    googleEvent(GOOGLE_EVENT_NAMES.checkOutValidationError, {
      category: step,
      label: errorMessage
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const events = {
  addToCart,
  beginCheckOut,
  checkOutProgress,
  purchase,
  removeFromCart,
  purchaseError,
  checkoutValidationError
};

export default events;
