export const FACEBOOK_EVENT_NAMES = {
  addToCart: 'AddToCart',
  removeFromCart: 'RemoveFromCart',
  beginCheckOut: 'InitiateCheckout',
  checkOutProgress: 'CheckoutProgress',
  purchase: 'Purchase',
  purchaseError: 'PurchaseError',
  viewContent: 'ViewContent',
  pageView: 'PageView'
};
