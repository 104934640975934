import { filter, partition, reduce } from 'ramda';

import { PROMO_CODE_TYPES } from 'domain/enums';
import { CartProductWQuantity, PromoCode } from 'domain/types';
import { calculatePriceWithQuantity } from '../products/calculatePrice';
import { NON_DISCOUNTABLE_CATEGORIES } from 'domain/constants/NON_DISCOUNTABLE_CATEGORIES';

const sumPrices = reduce<CartProductWQuantity, number>(
  (currentTotal, product) =>
    currentTotal + calculatePriceWithQuantity(product.price, product.discount, product.quantity),
  0
);

const calculatePersentageDiscount = (price: number, discount: number) =>
  Math.ceil(price * ((100 - discount) / 100));

const isNotEligibleForDiscount = (product: CartProductWQuantity) =>
  !!product.discount || NON_DISCOUNTABLE_CATEGORIES.includes(product.category.slug);

const sumPricesFixedDiscount = (cartProducts: CartProductWQuantity[], discount: number) => {
  const totalPrice = sumPrices(cartProducts);

  const notEligibleForDiscount = filter(isNotEligibleForDiscount, cartProducts);

  const totalNotEligibleForDiscount = sumPrices(notEligibleForDiscount);

  const totalPriceWithDiscount = totalPrice - discount;

  return totalNotEligibleForDiscount > totalPriceWithDiscount
    ? totalNotEligibleForDiscount
    : totalPriceWithDiscount;
};

const sumPricesPercentDiscount = (cartProducts: CartProductWQuantity[], discount: number) => {
  const [notEligible, eligible] = partition(isNotEligibleForDiscount, cartProducts);

  const totalNotEligible = sumPrices(notEligible);
  const totalEligible = calculatePersentageDiscount(sumPrices(eligible), discount);

  return totalNotEligible + totalEligible;
};

export const calculateTotalPrice = (
  cartProducts: CartProductWQuantity[],
  promoCode?: PromoCode | null
): number => {
  if (promoCode?.type === PROMO_CODE_TYPES.fixed)
    return sumPricesFixedDiscount(cartProducts, promoCode.discount);

  if (promoCode?.type === PROMO_CODE_TYPES.percentage)
    return sumPricesPercentDiscount(cartProducts, promoCode.discount);

  return sumPrices(cartProducts);
};
