import axiosInstance from 'axios';

import { LoggerRepo } from 'domain/repositories/LoggerRepo';

const axios = axiosInstance.create({});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    LoggerRepo.logError(error.response?.data || error.message || error);

    return Promise.reject(error.response?.data || error.message || error);
  }
);

export default axios;
