import axios from 'domain/services/axios';
import { CreateOrderDTO } from 'domain/types/Order';
import { OrderStatusStep } from 'domain/types/OrderStatusStep';
import { PayDto } from 'domain/types/PayDto';

export abstract class OrdersAPI {
  static async create(order: CreateOrderDTO) {
    const res = await axios.post<
      | {
          orderId: string;
        }
      | PayDto
    >('/api/order/', order);

    return res.data;
  }

  static async getOrderStatus(id: string) {
    const { data } = await axios.get<OrderStatusStep[]>(`/api/order-status/${id}`);

    return data;
  }

  static async pay(payDto: PayDto) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = 'https://www.liqpay.ua/api/3/checkout';
    form.acceptCharset = 'utf-8';

    const data = document.createElement('input');
    data.type = 'hidden';
    data.name = 'data';
    data.value = payDto.data;

    const signature = document.createElement('input');
    signature.type = 'hidden';
    signature.name = 'signature';
    signature.value = payDto.signature;

    const button = document.createElement('button');
    button.type = 'submit';
    button.style.visibility = 'hidden';

    form.appendChild(data);
    form.appendChild(signature);
    form.appendChild(button);

    document.body.appendChild(form);

    button.click();
  }
}
