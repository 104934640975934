import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

import Logo from 'public/logo.svg';
import Image from 'next/image';

const Spinner: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress size={64} color="secondary" />

      <Box className={classes.imageContainer}>
        <Image src={Logo} alt="Totem logo" height={32} width={32} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 140,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  imageContainer: {
    position: 'absolute',
    width: 32,
    height: 32,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

export default Spinner;
