export enum OrderStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PACKED = 'PACKED',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED'
}
