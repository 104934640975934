export const calculatePrice = (price: number, discount: number | null) => {
  if (discount && discount > 0 && discount <= 100) {
    const discountCoef = Math.min(discount / 100, 1);

    const discountedPrice = price * (1 - discountCoef);

    return Math.ceil(discountedPrice);
  }

  return Math.ceil(price);
};

export const calculatePriceWithQuantity = (
  price: number,
  discount: number | null,
  quantity: number
) => Math.ceil(calculatePrice(price, discount) * quantity);
