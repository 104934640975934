import { ORDER_STATUS_ORDER } from 'domain/constants';
import { OrderStatus } from 'domain/enums/OrderStatus';
import { OrderStatusStep } from 'domain/types/OrderStatusStep';

export const checkOrderStatusPaid = (statuses: OrderStatusStep[]): boolean => {
  if (statuses[statuses.length - 1]?.status === OrderStatus.DRAFT) {
    const currentStatus = statuses[0]?.status;

    const indexOfCurrentStatus = ORDER_STATUS_ORDER.indexOf(currentStatus);
    const indexOfPaidStatus = ORDER_STATUS_ORDER.indexOf(OrderStatus.PAYMENT_RECEIVED);

    if (indexOfCurrentStatus > 0 && indexOfPaidStatus <= indexOfCurrentStatus) {
      return true;
    }
  }

  return false;
};
