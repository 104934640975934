import { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { CartProduct, CartProductWQuantity } from 'domain/types';
import { cartVar } from 'utils/apollo/vars/cartVar';
import axios from 'domain/services/axios';

type CartProductsHookResult = {
  loading: boolean;
  error: string | null;
  products: CartProductWQuantity[];
  refetch: () => void;
};

export const useCartProducts = (): CartProductsHookResult => {
  const cartItems = useReactiveVar(cartVar);
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handeGetCartProducts = async (ids: string[]) => {
    try {
      setError(null);
      setLoading(true);
      const { data } = await axios.get('/api/cart', {
        params: {
          ids
        }
      });

      setCartProducts(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error?.message || 'Помилка завантаження товарів');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!cartItems.length) {
      handeGetCartProducts(cartItems.map(({ id }) => id));
    } else {
      setCartProducts([]);
    }
  }, [cartItems.length]);

  const productsWithQuantity = useMemo(
    () =>
      cartItems.length
        ? cartProducts.map((product) => ({
            ...product,
            quantity: cartItems.find(({ id }) => id === product.sys.id)?.quantity || 0
          }))
        : [],
    [cartProducts, cartItems]
  );

  return {
    loading,
    error,
    products: productsWithQuantity,
    refetch: () => handeGetCartProducts(cartItems.map(({ id }) => id))
  };
};
