import { OrderStatus } from 'domain/enums/OrderStatus';

export const MIN_ORDER_PRICE = 250;
export const MIN_CASH_ON_DELIVERY_PRICE = 500;
export const ADVANCED_PAYMENT_FOR_CASH_ON_DELIVERY = 250;

export const ORDER_STATUS_ORDER = [
  OrderStatus.CREATED,
  OrderStatus.CONFIRMED,
  OrderStatus.PAYMENT_RECEIVED,
  OrderStatus.PACKED,
  OrderStatus.SENT,
  OrderStatus.DELIVERED,
  OrderStatus.COMPLETED,
  OrderStatus.CANCELED
];

export const ORDER_STEPS = [
  { id: OrderStatus.CREATED, name: 'ЗАМОВЛЕННЯ ОФОРМЛЕНО', responsible: 'Автоматично' },
  { id: OrderStatus.CONFIRMED, name: 'ЗАМОВЛЕННЯ ПІДТВЕРДЖЕНО', responsible: 'Адміністратор' },
  { id: OrderStatus.PAYMENT_RECEIVED, name: 'ОПЛАТА ПІДТВЕРДЖЕНА', responsible: 'Адміністратор' },
  { id: OrderStatus.SENT, name: 'ЗАМОВЛЕННЯ ВІДПРАВЛЕНО', responsible: 'Адміністратор' },
  { id: OrderStatus.COMPLETED, name: 'ЗАМОВЛЕННЯ ОТРИМАНО', responsible: 'Клієнт' }
];

export const ORDER_AWAITING_PAYMENT_STEPS = [
  {
    id: OrderStatus.DRAFT,
    name: 'ЗАМОВЛЕННЯ ОФОРМЛЕНО',
    responsible: 'Автоматично',
    completed: true
  },
  {
    id: OrderStatus.DRAFT,
    name: 'ЗАМОВЛЕННЯ ПІДТВЕРДЖЕНО',
    responsible: 'Автоматично',
    completed: true
  },
  { id: OrderStatus.DRAFT, name: 'ОЧІКУЄМО ОПЛАТУ', responsible: 'Клієнт' },
  { id: OrderStatus.PAYMENT_RECEIVED, name: 'ОПЛАТА ПІДТВЕРДЖЕНА', responsible: 'Автоматично' },
  { id: OrderStatus.SENT, name: 'ЗАМОВЛЕННЯ ВІДПРАВЛЕНО', responsible: 'Адміністратор' },
  { id: OrderStatus.COMPLETED, name: 'ЗАМОВЛЕННЯ ОТРИМАНО', responsible: 'Клієнт' }
];

export const ORDER_CANCELED_STEP = {
  id: OrderStatus.CANCELED,
  name: 'ЗАМОВЛЕННЯ СКАСОВАНО',
  canceled: true,
  responsible: 'Адміністратор'
};

export const ORDER_CANCELED_STEPS = [...ORDER_STEPS, ORDER_CANCELED_STEP];
