import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { clearCart, initCart } from 'utils/apollo/vars/cartVar';
import { closeCart, showCartVar } from 'utils/apollo/vars/showCartVar';
import CartModal from './CartModal/CartModal';
import {
  LOCAL_STORAGE_KEYS,
  readLocalStorage,
  removeLocalStorage
} from 'utils/helpers/localStorage';
import { OrdersAPI } from 'domain/services/OrdersAPI/OrdersAPI';
import { checkOrderStatusPaid } from 'utils/helpers/orders/checkOrderStatusPaid';

interface CartProviderProps {
  children: React.ReactNode;
}

const CartProvider: React.FC<CartProviderProps> = ({ children }): JSX.Element => {
  const showCart = useReactiveVar(showCartVar);

  useEffect(() => {
    initCart();
  }, []);

  useEffect(() => {
    handleClearCartOnOrderPaid();
  }, []);

  const handleClearCartOnOrderPaid = async () => {
    try {
      const lastOrderId = readLocalStorage(LOCAL_STORAGE_KEYS.lastOrderId);
      const lastOrderDate = readLocalStorage(LOCAL_STORAGE_KEYS.lastOrderDate);

      if (lastOrderId && lastOrderDate) {
        const status = await OrdersAPI.getOrderStatus(lastOrderId);

        const wasPaid = checkOrderStatusPaid(status);

        if (wasPaid) {
          clearCart();
        }

        if (Date.now() - Number(lastOrderDate) > 1000 * 60 * 15 || wasPaid) {
          removeLocalStorage(LOCAL_STORAGE_KEYS.lastOrderId);
          removeLocalStorage(LOCAL_STORAGE_KEYS.lastOrderDate);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <>
      {children}

      <CartModal open={showCart} onClose={closeCart} />
    </>
  );
};

export default CartProvider;
