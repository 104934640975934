import { FACEBOOK_EVENT_NAMES } from 'domain/constants/FACEBOOK_EVENT_NAMES';

export const facebookPageview = () => {
  (window as any)?.fbq('track', FACEBOOK_EVENT_NAMES.pageView);
};

export const facebookProductView = ({
  price,
  name,
  productItemCode,
  categoryName
}: {
  price: number;
  name: string;
  categoryName: string;
  productItemCode: string;
}) => {
  try {
    (window as any)?.fbq('track', FACEBOOK_EVENT_NAMES.viewContent, {
      value: price,
      currency: 'UAH',
      content_name: name,
      content_type: 'product',
      content_ids: [productItemCode],
      content_category: categoryName
    });
  } catch {}
};

export const facebookEvent = (
  name: string,
  options: {
    content_name?: string;
    content_category?: string;
    content_ids?: string[];
    content_type?: string;
    value?: number;
    currency?: string;
    contents?: string;
    num_items?: number;
  } = {},
  custom = false
) => {
  (window as any).fbq(custom ? 'trackCustom' : 'track', name, options);
};
